<template>
  <div id="ai-services_kg_introduction">
    <service-info
      :icon="require('@/assets/images/icons/services/header/KG.svg')"
      service="kg"
    >
      <template v-slot:header>
        {{ $t('services.knowledgeGraph.header') }}
      </template>
      <template v-slot:subheader>
        {{ $t('services.knowledgeGraph.subHeader') }}
      </template>
      <template v-slot:buttons>
        <a
          id="try-arabrain-demo"
          href="http://arabrain.g42.ai/"
          target="_blank"
          class="btn-action"
        >{{ $t(`button['try demo']`) }}</a>
      </template>
    </service-info>
    <div class="methodology">
      <el-row type="flex">
        <el-col class="intro">
          <h1>{{ $t(`services.knowledgeGraph['Methodology Creation']`) }}</h1>
          <p><b>{{ $t(`services.knowledgeGraph['Starting from a set of interrelated documents']`) }}</b></p>
          <p>{{ $t(`services.knowledgeGraph['Co-mentioned entity names']`) }}</p>
        </el-col>
        <el-col class="bg-img" />
      </el-row>
    </div>
    <div class="methodology-use">
      <h1>
        <img src="@/assets/images/icons/services/introduction/kg/methodology.svg">
        {{ $t(`services.knowledgeGraph['Methodology Usage']`) }}
      </h1>
      <p><b>{{ $t(`services.knowledgeGraph['A typical search starts']`) }}</b></p>
      <p>{{ $t(`services.knowledgeGraph['The search journey proceeds']`) }}</p>
    </div>
    <div class="use-case">
      <el-row type="flex">
        <el-col class="bg-img" />
        <el-col class="intro">
          <h1>{{ $t(`services.knowledgeGraph['Use Cases']`) }}</h1>
          <p><b>{{ $t(`services.knowledgeGraph['Enterprises can use the G42 KG for']`) }}:</b></p>
          <p>{{ $t(`services.knowledgeGraph['Analyzing product review data']`) }}</p>
          <p>{{ $t(`services.knowledgeGraph['Mining news data to automatically']`) }}</p>
          <p>{{ $t(`services.knowledgeGraph['Making search more manageable']`) }}</p>
        </el-col>
      </el-row>
    </div>
    <div class="contact">
      <h2>{{ $t(`services.knowledgeGraph['Want a customized Knowledge Graph solution for your business']`) }}?</h2>
      <router-link to="/contact-us">
        <el-button>
          {{ $t(`button['contact us']`) }}
        </el-button>
      </router-link>
    </div>
    <div class="gallery">
      <h1>{{ $t(`services.knowledgeGraph['Knowledge Graph Gallery']`) }}</h1>
      <p>{{ $t(`services.knowledgeGraph['The following are our previous']`) }}</p>
      <div>
        <el-row :gutter="20">
          <el-col
            v-for="(image, index) of exampleList"
            :key="index"
            :xs="24"
            :md="8"
          >
            <div>
              <el-image
                :src="image"
                :preview-src-list="exampleList"
                :z-index="9001"
                fit="cover"
              />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import ServiceInfo from '@/views/pages/AIServices/components/ServiceTemplate/Info.vue';
import ImageExample01 from '@/assets/images/kg-example-01.png';
import ImageExample02 from '@/assets/images/kg-example-02.png';
import ImageExample03 from '@/assets/images/kg-example-03.png';

export default {
  name: 'KnowledgeGraph',
  components: {
    ServiceInfo,
  },
  data() {
    return {
      exampleList: [
        ImageExample01,
        ImageExample02,
        ImageExample03,
      ],
      exampleDesc: [
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquid assumenda at iste labore, laboriosam repellat sequi? Ab, accusamus aut deleniti, dicta ea est illum quam qui quis ut voluptatibus!',
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquid assumenda at iste labore, laboriosam repellat sequi? Ab, accusamus aut deleniti, dicta ea est illum quam qui quis ut voluptatibus!',
        'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aliquid assumenda at iste labore, laboriosam repellat sequi? Ab, accusamus aut deleniti, dicta ea est illum quam qui quis ut voluptatibus!',
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
  @mixin title {
    font-size: 0.28rem;
    font-weight: 800;
  }
  @mixin  content {
    margin: 1em 0;
    padding-right: 10%;
    font-size: 0.14rem;
    line-height: 1.5;
  }
  #ai-services_kg_introduction {
    padding: 0.2rem 0.4rem;
    background: #f6f8fe;
    .methodology {
      border-radius: 0.08rem;
      overflow: hidden;
      color: #FFF;
      background: #061C54;
      .intro {
        padding: 0.32rem;
        background: url(~@/assets/images/icons/services/introduction/kg/bg-rect-blue.svg) left top no-repeat;
        h1 {
          @include title;
        }
        p {
          @include content;
        }
      }
      .bg-img {
        background: url(~@/assets/images/background/methodology.png) center no-repeat;
        background-size: cover;
      }
    }
    .methodology-use {
      margin: {
        top: 0.3rem;
        bottom: 0.3rem;
      }
      h1 {
        margin-bottom: 0.2rem;
        display: flex;
        align-items: center;
        @include title;
        img {
          margin-right: 0.18rem;
          padding: 0.3em;
          border-radius: 100%;
          background: #FFF;
          box-shadow: $icon-shadow;
        }
      }
      p {
        @include content;
      }
    }
    .use-case {
      border-radius: 0.08rem;
      overflow: hidden;
      color: #FFF;
      background: #B41E01;
      .intro {
        padding: 0.32rem;
        background: url(~@/assets/images/icons/services/introduction/kg/bg-rect-red.svg) right top no-repeat;
        h1 {
          @include title;
        }
        p {
          @include content;
        }
      }
      .bg-img {
        background: url(~@/assets/images/background/use-cases.png) center no-repeat;
        background-size: cover;
      }
    }
    .contact {
      margin: 0.28rem 0;
      padding: 0.28rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0.08rem;
      background: #061C54;
      h2 {
        max-width: 60%;
        font-size: 0.28rem;
        font-weight: 700;
        color: #FFF;
      }
      .el-button {
        width: 2rem;
        height: 0.6rem;
        border: none;
        color: #FFF;
        background: linear-gradient(100.62deg, #3A71FF 48.27%, rgba(121, 58, 255, 0) 203.77%);
      }
    }
    .gallery {
      h1 {
        @include title;
      }

      p {
        margin: 0.32rem 0;
      }

      .el-row {
        .el-col {
          &>div {
            position: relative;
            width: 100%;
            border-radius: 0.06rem;
            overflow: hidden;
            box-shadow: 0 0.05rem 0.14rem rgba(180, 185, 193, 0.27);
            .el-image {
              width: 100%;
            }
            /deep/ .el-icon-circle-close:before {
              color: #FFF;
            }
          }
        }
      }
    }
  }

  #try-arabrain-demo {
    margin-left: 0.16rem;
    height: 0.42rem;
    border: 1px solid;
    padding: 0.12rem 0.16rem;
    background: $background-footer;
    background-image: linear-gradient($background-footer, $background-footer), $button-service-gradient;
    background-origin: border-box;
    background-clip: content-box, border-box;
    font-size: 0.14rem;
    color: #3A71FF;
    background: transparent;
  }
</style>
